import React from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "../../../components/Buttons/Buttons";
import { Panel } from "../../../components/Panel/Panel";

// CSS
import styles from "./dashboard-compose.module.scss";

// Localization
import { useTranslation } from "localization/localization";

// Logging
import { AnalyticsLogger } from "../../../loggers/AnalyticsLogger";

// Media
import planeImageSrc from "media/letters/artwork/plane.svg";

// Utils
import { isEmptyObject } from "utils/miscUtils";

export const DashboardCompose = ({
    activeDraft,
    currentActiveWriters,
    history,
}) => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    const { draftRecipient } = activeDraft || {};

    const hasActiveDraft =
        activeDraft && !isEmptyObject(activeDraft) && draftRecipient;
    const buttonText = hasActiveDraft
        ? t("compose.finish_letter", { ns: "dashboard" })
        : t("compose.start_writing", { ns: "dashboard" });
    const subButtonText =
        currentActiveWriters < 10
            ? t("compose.every_day_support", { ns: "dashboard" })
            : t("compose.active_writers", {
                  count: currentActiveWriters,
                  ns: "dashboard",
              });

    function handleComposeClick() {
        AnalyticsLogger.logLetterComposeStart();
        history.push("/letters/compose");
        draftRecipient &&
            localStorage.setItem(
                "sandboxxMessageRecipient",
                JSON.stringify(draftRecipient)
            );
    }

    function renderDraftNotification() {
        return (
            hasActiveDraft && (
                <span className={styles.text}>
                    {t("compose.finish_your_letter_to", {
                        firstName: draftRecipient.firstName,
                        ns: "dashboard",
                    })}
                </span>
            )
        );
    }

    return (
        <Panel>
            <div className={styles["dashboard__compose"]}>
                <img
                    alt={t("compose.alt_paper_plane", { ns: "dashboard" })}
                    className={styles.image}
                    src={planeImageSrc}
                />
                {renderDraftNotification()}
                <ButtonPrimary
                    classes={cx(styles.button, "button--size-short")}
                    color="orange"
                    cypressTestId="dashboard-compose-button"
                    onClick={handleComposeClick}
                    text={buttonText}
                    type="button"
                />
                <div className={styles.text}>
                    <span>{subButtonText}</span>
                </div>
            </div>
        </Panel>
    );
};
