/**
 * Returns a boolean based on (a) if we have add-ons total, we need to ensure they have a card selected,
 * otherwise if no add ons total, we simply return true, enabling the submit button.
 *
 * @param {Boolean} hasAddOnsTotal used to determine if the total value of add ons is greater than 0 or not
 * @param {Boolean} hasSelectedCreditCard used to determine if the user currently has a selected card
 * @return {Boolean} indicating if the submit button on the review screen should be enabled
 */
export function canSubmitLetterWithAddOns(
    hasAddOnsTotal,
    hasSelectedCreditCard
) {
    return hasAddOnsTotal ? hasSelectedCreditCard : true;
}

/**
 * Generate a boolean that indicates whether Postage Paid was automatically removed from
 * the user's cart due to the sender address no longer being valid
 *
 * @param {Array} newAddOns contains add-ons that were just fetched
 * @param {Object} prevAddOns contains add-ons data from previously fetched batch
 * @return {Boolean} indicating whether Postage Paid was automatically removed from cart
 */
export function determineIfPostagePaidRemoved(newAddOns, prevAddOns) {
    const newAddOnsContainPostagePaid = newAddOns.find(
        (addOn) => addOn.productType === "POSTAGE"
    );
    const prevAddOnsContainPostagePaid = prevAddOns?.find(
        (addOn) => addOn.productType === "POSTAGE"
    );
    return !newAddOnsContainPostagePaid && prevAddOnsContainPostagePaid
        ? true
        : false;
}

/**
 * Generates an add-on's `isSelected` status based on whether (a) it was previously
 * in the user's cart and (b) whether it is still available as a selectable add-on
 *
 * @param {Object} option contains an add-on that was just fetched
 * @param {Object} addOns contains all current add-ons data in state
 * @return {Boolean} indicating what the add-on option's `isSelected` value should be
 */
export function generateIsSelectedStatus(option, addOns) {
    const currentId = option?.id;
    const previousAddOn = addOns?.find((addOn) => addOn?.id === currentId);
    return previousAddOn ? previousAddOn.isSelected : false;
}

/**
 * Returns a boolean indicating whether a variant of the considered add-on has already
 * been selected by the user
 *
 * @param {Object} param0
 * @param {Object} param0.addOns Object containing all potentially available add-ons and their selected status
 * @param {Object} param0.item Individual add-on being considered
 * @returns {Boolean} indicating whether a variant of the add-on has already been selected
 */
export function isAddOnVariantAlreadySelected({ addOns, item }) {
    return addOns.some((addOn) => {
        if (addOn.id === item.id) return false;
        if (item.isSelected) return false;
        return (
            addOn.isSelected &&
            addOn?.productDetails?.newsType === item?.productDetails?.newsType
        );
    });
}
