// Enums
import { PaymentBrand } from "enums/payments/PaymentBrand";
import { PaymentType } from "enums/payments/PaymentType";

// Media
import amexSrc from "media/icons/credit-cards/amex.svg";
import applePaySrc from "media/icons/credit-cards/applepay.svg";
import defaultCardSrc from "media/icons/credit-cards/default.svg";
import dinersClubSrc from "media/icons/credit-cards/dinersclub.svg";
import discoverSrc from "media/icons/credit-cards/discover.svg";
import jcbSrc from "media/icons/credit-cards/jcb.svg";
import maestroSrc from "media/icons/credit-cards/maestro.svg";
import masterCardSrc from "media/icons/credit-cards/mastercard.svg";
import visaSrc from "media/icons/credit-cards/visa.svg";

/* istanbul ignore next */
export function generateCreditCardIconSrc(brand) {
    if (brand?.includes(PaymentType.APPLE_PAY)) {
        return applePaySrc;
    }
    switch (brand) {
        case PaymentBrand.AMERICAN_EXPRESS:
            return amexSrc;
        case PaymentBrand.DINERS_CLUB:
            return dinersClubSrc;
        case PaymentBrand.DISCOVER:
            return discoverSrc;
        case PaymentBrand.JCB:
            return jcbSrc;
        case PaymentBrand.MAESTRO:
            return maestroSrc;
        case PaymentBrand.MASTERCARD:
            return masterCardSrc;
        case PaymentBrand.VISA:
            return visaSrc;
        default:
            return defaultCardSrc;
    }
}
