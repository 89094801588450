import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";

// Components
import { Panel } from "components/Panel/Panel";
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./order-history-item.module.scss";

// Models
import { OrderHistoryOrder } from "models/order-history/OrderHistoryOrder";

// Utils
import { generateCreditCardIconSrc } from "utils/purchaseUtils";

export const OrderHistoryItem = ({ isLoading, item }) => {
    function renderItem() {
        if (item) {
            const transactionDatePretty = DateTime.fromISO(
                item.transactionDate
            ).toLocaleString(DateTime.DATETIME_FULL);
            return (
                <Panel classes={styles.orderHistoryItem}>
                    <div className={styles.date}>
                        <Text>{transactionDatePretty}</Text>
                    </div>
                    <div className={styles.products}>
                        {item.products.map((product, i) => (
                            <div className={styles.product} key={i}>
                                <Text isBold>{product.title}</Text>
                                {i === 0 && (
                                    <Text isBold>${item.totalPrice}</Text>
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={styles.orderNumber}>
                        <Text>Order Number:</Text>
                        <Text>{item.orderNumber}</Text>
                    </div>
                    {renderPaymentMethod()}
                </Panel>
            );
        }
    }

    function renderLoading() {
        return (
            <Panel classes={styles.orderHistoryItem}>
                <div className={styles.date}>
                    <Skeleton className="skeleton" height={12} width="20%" />
                </div>
                <div className={styles.products}>
                    <div className={styles.product}>
                        <Skeleton
                            className="skeleton"
                            height={16}
                            width="50%"
                        />
                        <Skeleton
                            className="skeleton"
                            height={16}
                            width="15%"
                        />
                    </div>
                </div>
                <div className={styles.orderNumber}>
                    <Skeleton className="skeleton" height={12} width="30%" />
                </div>
                <div className={styles.paymentMethod}>
                    <Skeleton className="skeleton" height={16} width={32} />
                    <Skeleton className="skeleton" height={12} width="20%" />
                </div>
            </Panel>
        );
    }

    function renderPaymentMethod() {
        if (item?.payment?.type) {
            return (
                <div className={styles.paymentMethod}>
                    <img
                        alt={item.payment.cardType}
                        className={styles.icon}
                        src={generateCreditCardIconSrc(item.payment.cardType)}
                    />
                    <Text>
                        {item.payment.cardType} ending in{" "}
                        {item.payment.lastFour}
                    </Text>
                </div>
            );
        }
        return null;
    }

    return isLoading ? renderLoading() : renderItem();
};

OrderHistoryItem.propTypes = {
    item: PropTypes.instanceOf(OrderHistoryOrder),
    isLoading: PropTypes.bool,
};
