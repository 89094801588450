import React from "react";
import PropTypes from "prop-types";

// Components
import { Checkbox } from "components/Inputs/Checkbox/Checkbox";
import CreateIcon from "@mui/icons-material/Create";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Text } from "components/Text/Text";

// CSS
import styles from "./muster-checklist-item.module.scss";

// Enum
import { MusterChecklistModalTypes } from "enums/MusterChecklistModalTypes";

export const MusterChecklistItem = ({
    completed,
    document,
    id,
    onToggleAddEditModal,
    onToggleDeleteModal,
    text,
    updateMusterDocument,
}) => {
    return (
        <div className={styles.musterChecklistItem}>
            <div className={styles.content}>
                <Checkbox
                    checked={completed}
                    classes={styles.checkbox}
                    id={`checkbox-${id}`}
                    onChange={() =>
                        updateMusterDocument(
                            document.categoryId,
                            id,
                            text,
                            !completed
                        )
                    }
                />
                <div className={styles.textContainer}>
                    <Text type="p">{text}</Text>
                </div>
            </div>
            <div className={styles.icons}>
                <button
                    className={styles.iconButton}
                    onClick={() =>
                        onToggleAddEditModal(
                            true,
                            MusterChecklistModalTypes.EDIT,
                            document
                        )
                    }
                >
                    <CreateIcon className={styles.icon} />
                </button>
                <button
                    className={styles.iconButton}
                    onClick={() => onToggleDeleteModal(true, document)}
                >
                    <DeleteOutlineIcon className={styles.icon} />
                </button>
            </div>
        </div>
    );
};

MusterChecklistItem.propTypes = {
    completed: PropTypes.bool.isRequired,
    document: PropTypes.object.isRequired,
    id: PropTypes.number.isRequired,
    onToggleAddEditModal: PropTypes.func.isRequired,
    onToggleDeleteModal: PropTypes.func.isRequired,
    text: PropTypes.string.isRequired,
    updateMusterDocument: PropTypes.func.isRequired,
};
