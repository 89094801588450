import React, { useContext } from "react";
import { Link } from "react-router-dom";

// Components
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Text } from "components/Text/Text";

// Context
import { HeaderBarContext } from "../../context/headerBar";

// CSS
import styles from "./header-bar.module.scss";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const HeaderBar = (props) => {
    const { children } = props;

    /**
     * useContext
     **/

    const { headerBarContent } = useContext(HeaderBarContext);
    const { backPathname, onBackClick, text, textSecondary } = headerBarContent;

    /**
     * End Hooks & Constants
     **/

    function renderBackOption() {
        if (backPathname) {
            return (
                <Link
                    className={styles.back}
                    to={backPathname}
                    data-cy="header-bar-back-link"
                >
                    <ArrowBackIcon />
                </Link>
            );
        } else if (onBackClick) {
            return (
                <div
                    className={styles.back}
                    data-cy="header-bar-back-link"
                    onClick={onBackClick}
                    onKeyDown={(e) => handleKeyDown(e, onBackClick)}
                    tabIndex="0"
                >
                    <ArrowBackIcon />
                </div>
            );
        }
        return;
    }

    function renderTextSecondary() {
        return (
            textSecondary && (
                <Text
                    classes={styles.textSecondary}
                    cyId="header-bar-text-secondary"
                >
                    {textSecondary}
                </Text>
            )
        );
    }

    return (
        <div className={styles.headerBar}>
            <div className={styles.left}>
                {renderBackOption()}
                <div className={styles.content}>
                    <Text classes={styles.text} cyId="header-bar-text">
                        {text}
                    </Text>
                    {renderTextSecondary()}
                </div>
            </div>
            <div className={styles.right}>{children}</div>
        </div>
    );
};
