import React from "react";
import PropTypes from "prop-types";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-confirmation.module.scss";

// Hooks
import { useCartConfirmation } from "./hooks/useCartConfirmation";

/**
 * TODO: Replace description text once cart offerings expand beyond Memory Books
 */
export const CartConfirmation = ({ resetCartNavigation }) => {
    /**
     * Custom Hooks
     */

    const { closeCartAfterConfirmation, completedOrder, orderPlacedDateTime } =
        useCartConfirmation({
            resetCartNavigation,
        });

    /**
     * End Hooks
     */

    return (
        <div className={styles.cartConfirmation} data-cy="cart-confirmation">
            <div className={styles.header}>
                <CheckCircleIcon className={styles.icon} />
                <Text classes={styles.header} isBold>
                    Thank you for your order!
                </Text>
            </div>
            <div className={styles.orderNumber}>
                <Text isBold>Order Number</Text>
                <Text>{completedOrder.display_id}</Text>
            </div>
            <div className={styles.orderPlaced}>
                <Text isBold>Order Placed</Text>
                <Text>{orderPlacedDateTime}</Text>
            </div>
            <Text classes={styles.description}>
                In the next 24 hours, we will begin printing your Memory Book.
                You will receive further updates as we produce and ship your
                book.
            </Text>
            <ButtonPrimary
                className={styles.button}
                onClick={closeCartAfterConfirmation}
            >
                <Text>Continue</Text>
            </ButtonPrimary>
        </div>
    );
};

CartConfirmation.propTypes = {
    resetCartNavigation: PropTypes.func.isRequired,
};
