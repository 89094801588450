import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Text } from "components/Text/Text";

// CSS
import styles from "./slider.module.scss";

// Hooks
import { handleKeyDown } from "utils/eventUtils";
import { useSlider } from "./hooks/useSlider";

export const Slider = (props) => {
    const {
        classNames,
        images,
        shouldShowArrows,
        shouldShowMarkers,
        shouldShowText,
        text,
    } = props;

    /**
     * Custom Hooks
     */

    const {
        handleSelectMarker,
        handleSelectLeftArrow,
        handleSelectRightArrow,
        sliderIndex,
    } = useSlider({ images });

    /**
     * End Hooks
     */

    function renderMarkers() {
        if (images?.length > 1) {
            return (
                <div className={styles.markers}>
                    {images.map((image, i) => {
                        return (
                            <div
                                className={cx(styles.marker, {
                                    [styles["marker--active"]]:
                                        i === sliderIndex,
                                })}
                                key={i}
                                onClick={() => handleSelectMarker(i)}
                                onKeyDown={(e) =>
                                    handleKeyDown(e, () =>
                                        handleSelectMarker(i)
                                    )
                                }
                                tabIndex="0"
                            />
                        );
                    })}
                </div>
            );
        }
    }

    function renderArrows() {
        const canGoLeft = sliderIndex > 0;
        const canGoRight = sliderIndex < images.length - 1;
        return (
            <div className={cx(styles.arrows, classNames.arrows)}>
                <span
                    className={cx(styles.arrow, styles.left, {
                        [styles["arrow--disabled"]]: !canGoLeft,
                    })}
                    onClick={handleSelectLeftArrow}
                    onKeyDown={(e) => handleKeyDown(e, handleSelectLeftArrow)}
                >
                    <ChevronLeftIcon />
                </span>
                <span
                    className={cx(styles.arrow, styles.right, {
                        [styles["arrow--last"]]: !canGoRight,
                    })}
                    onClick={handleSelectRightArrow}
                    onKeyDown={(e) => handleKeyDown(e, handleSelectRightArrow)}
                >
                    <ChevronRightIcon />
                </span>
            </div>
        );
    }

    function renderText() {
        return (
            <>
                {text[sliderIndex]?.title && (
                    <Text classes={cx(styles.title, classNames.title)} isBold>
                        {text[sliderIndex].title}
                    </Text>
                )}
                {text[sliderIndex]?.subtitle && (
                    <Text classes={cx(styles.subtitle, classNames.subtitle)}>
                        {text[sliderIndex].subtitle}
                    </Text>
                )}
            </>
        );
    }

    return (
        <div className={cx(styles.slider, classNames.container)}>
            <div
                className={cx(styles.image, classNames.image)}
                style={{ backgroundImage: `url(${images[sliderIndex]})` }}
            />
            <div className={styles.body}>{shouldShowText && renderText()}</div>
            {shouldShowMarkers && renderMarkers()}
            {shouldShowArrows && renderArrows()}
        </div>
    );
};

Slider.defaultProps = {
    classNames: {
        arrows: "",
        container: "",
        image: "",
        headline: "",
        subText: "",
    },
    shouldShowArrows: false,
    shouldShowMarkers: true,
    shouldShowText: false,
};

Slider.propTypes = {
    classNames: PropTypes.object,
    images: PropTypes.array.isRequired,
    shouldShowArrows: PropTypes.bool,
    shouldShowMarkers: PropTypes.bool,
    shouldShowText: PropTypes.bool,
    text: PropTypes.array,
};
