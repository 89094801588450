/**
 * OrderHistoryOrder
 * @class representing each individual order present in a user's order history
 */
export class OrderHistoryOrder {
    /**
     * Schema
     * @param {Object} orderData
     * -- @param {String} orderData.orderNumber
     * -- @param {Object} orderData.payment
     * ---- @param {String} orderData.payment.cardType
     * ---- @param {String} orderData.payment.lastFour
     * ---- @param {String} orderData.payment.type
     * -- @param {Array} orderData.products
     * ---- @param {String} orderData.products.price
     * ---- @param {Number} orderData.products.quantity
     * ---- @param {String} orderData.products.title
     * -- @param {Array} orderData.refunds
     * -- @param {String} orderData.totalPrice
     * -- @param {String} orderData.transactionDate
     */
    constructor(orderData) {
        Object.keys(orderData).forEach((key) => {
            this[key] = orderData[key];
        });
    }
}
