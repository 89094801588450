import React from "react";
import cx from "classnames";

// Components
import { ButtonPrimary } from "components/Buttons/Buttons";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { OrderHistoryItem } from "./OrderHistoryItem/OrderHistoryItem";
import { Panel } from "components/Panel/Panel";
import { Text } from "components/Text/Text";

// CSS
import styles from "./order-history.module.scss";

// Hooks
import { useOrderHistory } from "./hooks/useOrderHistory";

export const OrderHistory = () => {
    /**
     * Custom Hooks
     */

    const { cursor, fetchOrderHistory, isLoading, orders } = useOrderHistory();

    /**
     * End Hooks
     */

    function renderButton() {
        if (orders?.length) {
            return (
                <ButtonPrimary
                    classes={styles.button}
                    isDisabled={!cursor}
                    isLoading={isLoading}
                    onClick={fetchOrderHistory}
                >
                    <Text>Load More</Text>
                </ButtonPrimary>
            );
        }
    }

    function renderEmpty() {
        if (!isLoading && orders?.length === 0) {
            return (
                <Panel classes={styles.empty}>
                    <Inventory2OutlinedIcon className={styles.icon} />
                    <Text isBold>No past orders</Text>
                    <Text>Orders placed in the future can be found here.</Text>
                </Panel>
            );
        }
    }

    function renderItems() {
        if (isLoading && !orders) {
            return (
                <div className={styles.items}>
                    <OrderHistoryItem isLoading={isLoading} />
                    <OrderHistoryItem isLoading={isLoading} />
                    <OrderHistoryItem isLoading={isLoading} />
                </div>
            );
        } else {
            return (
                <div className={styles.items}>
                    {orders?.map((order) => (
                        <OrderHistoryItem
                            key={order.orderNumber}
                            item={order}
                        />
                    ))}
                </div>
            );
        }
    }

    return (
        <div className="page">
            <HeaderBar />
            <div className={cx(styles.orderHistory, "page__body")}>
                {renderEmpty()}
                {renderItems()}
                {renderButton()}
            </div>
        </div>
    );
};
