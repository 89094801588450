import React from "react";

// Components
import { MenuItem } from "@mui/material";
import { Select } from "components/Inputs/Select/Select";
import { Skeleton } from "@mui/material";
import { Text } from "components/Text/Text";

// CSS
import styles from "./cart-shipping-options-select.module.scss";

// Hooks
import { useCartShippingOptionsSelect } from "./hooks/useCartShippingOptionsSelect";

// Utils
import { convertWholeNumberToDollars } from "utils/cartUtils";

export const CartShippingOptionsSelect = () => {
    /**
     * Custom Hooks
     */

    const {
        cart,
        handleChange,
        isSubmitting,
        isSubmittingCart,
        selectedValue,
        shippingOptions,
    } = useCartShippingOptionsSelect();

    /**
     * Constants
     */

    const selectedShippingOption = cart?.shipping_methods[0] || null;

    /**
     * End Hooks
     */

    function renderEmptyOption() {
        return (
            <MenuItem sx={{ pl: 0 }} value="">
                <div className={styles.option}>
                    <Text classes={styles.title}>Select Shipping Method</Text>
                </div>
            </MenuItem>
        );
    }

    function renderLoading() {
        return (
            <div className={styles.cartShippingOptionsSelect}>
                <Select
                    className={styles.select}
                    cypressTestId="cart-shipping-options-select"
                    selectProps={{
                        disabled: true,
                        displayEmpty: true,
                        native: false,
                        renderValue: () => (
                            <div className={styles.option}>
                                <Skeleton
                                    className={styles.skeleton}
                                    variant="text"
                                    width="50%"
                                />
                            </div>
                        ),
                    }}
                />
            </div>
        );
    }

    /**
     *
     * @param {Object}  param0
     * @param {Boolean} param0.isSelected indicates whether the provided object references the user's selected shipping option
     * @param {Object}  param0.option object containing selected shipping option data
     * @returns {Node}
     */
    function renderOption({ isSelected, option }) {
        if (isSelected) {
            /**
             * If a selected shipping option is present in the cart, use that data object
             * to populate the selected option in the shipping option dropdown. This requires
             * referencing different keys than would be referenced in non-selected options.
             */
            return (
                <MenuItem
                    key={option.id}
                    sx={{ pl: isSelected ? 0 : "auto" }}
                    value={option?.shipping_option}
                >
                    <div className={styles.option}>
                        <Text classes={styles.title}>
                            {option?.shipping_option?.name}
                        </Text>
                        <Text classes={styles.amount} isBold>
                            {convertWholeNumberToDollars(option?.subtotal)}
                        </Text>
                    </div>
                </MenuItem>
            );
        } else {
            /**
             * This else statement renders all dropdown options except for the one that
             * renders the user's selected option.
             */
            return (
                <MenuItem
                    key={option.id}
                    sx={{ pl: isSelected ? 0 : "auto" }}
                    value={option}
                >
                    <div className={styles.option}>
                        <Text classes={styles.title}>{option?.name}</Text>
                        <Text classes={styles.amount} isBold>
                            {convertWholeNumberToDollars(option?.amount)}
                        </Text>
                    </div>
                </MenuItem>
            );
        }
    }

    function renderSelect() {
        return (
            <div className={styles.cartShippingOptionsSelect}>
                <Select
                    className={styles.select}
                    cypressTestId="cart-shipping-options-select"
                    name="shippingOption"
                    onChange={handleChange}
                    required
                    selectProps={{
                        "data-cy": "cart-shipping-options-select-container",
                        disabled: isSubmitting || isSubmittingCart,
                        displayEmpty: true,
                        native: false,
                        renderValue: () =>
                            selectedShippingOption
                                ? renderOption({
                                      option: selectedShippingOption,
                                      isSelected: true,
                                  })
                                : renderEmptyOption(),
                    }}
                    value={selectedValue}
                >
                    <MenuItem disabled value="">
                        <div className={styles.option}>
                            <Text classes={styles.title}>
                                Select Shipping Method
                            </Text>
                        </div>
                    </MenuItem>
                    {shippingOptions.map((shippingOption) =>
                        renderOption({ option: shippingOption })
                    )}
                </Select>
            </div>
        );
    }

    if (!shippingOptions) {
        return renderLoading();
    } else {
        return renderSelect();
    }
};
