import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

// Components
import { Banner } from "components/Banner/Banner";
import { DepModal } from "../../components/DepModal/DepModal";
import { HeaderBar } from "components/HeaderBar/HeaderBar";
import { ImageCropModal } from "components/ImageCropModal/ImageCropModal";
import Inventory2OutlinedIcon from "@mui/icons-material/Inventory2Outlined";
import { LetterIcon } from "components/Icons/LetterIcon";
import { LineItem } from "components/LineItem/LineItem";
import { ProfileAddressBook } from "./components/ProfileAddressBook/ProfileAddressBook";
import { ProfileFields } from "./components/ProfileFields/ProfileFields";
import { ProfileHeader } from "./components/ProfileHeader/ProfileHeader";
import { ProfileOnboardingNotificationCorner } from "./components/ProfileOnboardingNotificationCorner/ProfileOnboardingNotificationCorner";
import { ProfilePersonaModal } from "./components/ProfilePersonaModal/ProfilePersonaModal";
import { ProfileShipDateModal } from "./components/ProfileShipDateModal/ProfileShipDateModal";
import { Text } from "components/Text/Text";

// Context
import { NotificationBarContext } from "../../context/notificationBar";

// Enums
import { ImageAspectRatio } from "components/ImageCropModal/enums/ImageAspectRatio";

// Hooks
import { useDocumentTitle } from "@uidotdev/usehooks";
import { useProfile } from "./hooks/useProfile";

// CSS
import styles from "./profile.module.scss";

// Platforms
import { CurrentUser } from "utils/sandboxx";
import { ProfileDeleteAccountModal } from "./components/ProfileDeleteAccountModal/ProfileDeleteAccountModal";

export const Profile = (props) => {
    /**
     * Router Hooks
     */

    const history = useHistory();

    /**
     * useContext
     */

    const { showNotification } = useContext(NotificationBarContext);

    /**
     * Custom Hooks
     */

    useDocumentTitle("Sandboxx - Profile");

    const {
        componentState,
        credits,
        depsLocations,
        fetchAccount,
        fetchDepsLocations,
        handleImageCancel,
        imagePreview,
        handleGoToContact,
        handleGoToOnboarding,
        handleImageFileSelect,
        handlePurchaseModalToggle,
        handleSaveCroppedImage,
        handleShowCompleteProfileNotification,
        handleShowDepModal,
        handleShowPersonaSelectionModal,
        handleShowShipDateModal,
        onProfileUpdateSuccess,
        showImageCropModal,
        showProfileDeleteAccountModal,
        setShowProfileDeleteAccountModal,
        togglePhoneVerificationModal,
    } = useProfile();

    /**
     * Constants
     */

    const {
        depLocation,
        display,
        lettersSent,
        profileInfo,
        shouldShowOnboardingNotification,
    } = componentState;
    const {
        shouldShowDepModal,
        shouldShowPersonaModal,
        shouldShowShipDateModal,
    } = display;
    const user = CurrentUser.getUser();

    /**
     * End Hooks & Constants
     */

    function renderPhoneVerificationBanner() {
        return (
            !CurrentUser.isVerified() && (
                <Banner
                    classes={styles.banner}
                    config={{ isActive: true, shouldShowChevron: true }}
                    onClick={() =>
                        togglePhoneVerificationModal(true, "profile")
                    }
                    tabFlow={true}
                    theme="blue"
                >
                    <Text isBold>Connect with your recruit</Text>
                    <Text>Verify your phone number</Text>
                </Banner>
            )
        );
    }

    return (
        <div className="page">
            <DepModal
                {...props}
                depsLocations={depsLocations}
                fetchDepsLocations={fetchDepsLocations}
                handleShowDepModal={handleShowDepModal}
                onSetDepLocation={fetchAccount}
                shouldShow={shouldShowDepModal}
                user={user}
            />
            <ProfileDeleteAccountModal
                {...props}
                profileInfo={profileInfo}
                setShowProfileDeleteAccountModal={
                    setShowProfileDeleteAccountModal
                }
                showNotification={showNotification}
                showProfileDeleteAccountModal={showProfileDeleteAccountModal}
            />
            <ProfileOnboardingNotificationCorner
                {...props}
                handleGoToOnboarding={handleGoToOnboarding}
                handleShowCompleteProfileNotification={
                    handleShowCompleteProfileNotification
                }
                shouldShowOnboardingNotification={
                    shouldShowOnboardingNotification
                }
            />
            <ImageCropModal
                aspect={ImageAspectRatio.PROFILE}
                finalWidth={1200}
                handleImageCancel={handleImageCancel}
                handleSaveCroppedImage={handleSaveCroppedImage}
                showImageCropModal={showImageCropModal}
                imagePreview={imagePreview}
            />
            <ProfilePersonaModal
                {...props}
                handleShowPersonaSelectionModal={
                    handleShowPersonaSelectionModal
                }
                profileInfo={profileInfo}
                onProfileUpdateSuccess={onProfileUpdateSuccess}
                shouldShowPersonaModal={shouldShowPersonaModal}
            />
            <ProfileShipDateModal
                {...props}
                handleShowShipDateModal={handleShowShipDateModal}
                profileInfo={profileInfo}
                onProfileUpdateSuccess={onProfileUpdateSuccess}
                shouldShow={shouldShowShipDateModal}
                showNotification={showNotification}
            />
            <HeaderBar />
            <div className="page__body">
                <ProfileHeader
                    {...props}
                    credits={credits}
                    handleImageFileSelect={handleImageFileSelect}
                    handlePurchaseModalToggle={handlePurchaseModalToggle}
                    lettersSent={lettersSent}
                    profileInfo={profileInfo}
                    user={user}
                />
                <div className={styles.profile}>
                    {renderPhoneVerificationBanner()}
                    <div className={styles.grid}>
                        <div className={styles["column--one"]}>
                            <ProfileFields
                                {...props}
                                currentUser={user}
                                depLocation={depLocation}
                                display={display}
                                handleShowProfileDepsModal={handleShowDepModal}
                                handleShowPersonaSelectionModal={
                                    handleShowPersonaSelectionModal
                                }
                                handleShowShipDateModal={
                                    handleShowShipDateModal
                                }
                                onProfileUpdateSuccess={onProfileUpdateSuccess}
                                profileInfo={profileInfo}
                                setShowProfileDeleteAccountModal={
                                    setShowProfileDeleteAccountModal
                                }
                                shouldShowShipDateModal={
                                    shouldShowShipDateModal
                                }
                                togglePhoneVerificationModal={
                                    togglePhoneVerificationModal
                                }
                            />
                            <div
                                className={styles.extraLinks}
                                data-cy="profile-extra-links"
                            >
                                <LineItem
                                    className={styles.item}
                                    icon={
                                        <Inventory2OutlinedIcon
                                            className={styles.icon}
                                        />
                                    }
                                    onClick={() =>
                                        history.push("/order-history")
                                    }
                                    text="Order History"
                                />
                                <LineItem
                                    className={styles.item}
                                    icon={<LetterIcon width="20px" />}
                                    onClick={() =>
                                        history.push("/letters/referrals")
                                    }
                                    text="Get Free Letters"
                                />
                            </div>
                        </div>
                        <div className={styles["column--two"]}>
                            <ProfileAddressBook
                                {...props}
                                handleGoToContact={handleGoToContact}
                                user={user}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
