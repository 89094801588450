import { useContext, useEffect, useState } from "react";

// API
import { CurrentUser, SandboxxRestAPI } from "utils/sandboxx";

// Context
import { HeaderBarContext } from "context/headerBar";

// Models
import { OrderHistoryOrder } from "models/order-history/OrderHistoryOrder";

// Utils
import { scrollToTop } from "utils/scrollUtils";

export const useOrderHistory = () => {
    /**
     * useContext
     */

    const { setHeaderBarContent } = useContext(HeaderBarContext);

    /**
     * useState
     */

    const [cursor, setCursor] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [orders, setOrders] = useState(null);

    /**
     * useEffect
     */

    useEffect(() => {
        fetchOrderHistory();
        setHeaderBarContent({
            backPathname: "/profile",
            text: "Order History",
        });
        scrollToTop();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * End Hooks
     */

    async function fetchOrderHistory() {
        setIsLoading(true);
        try {
            const { json } = await SandboxxRestAPI.getUserOrderHistory({
                cursor,
                userId: CurrentUser.getUser().userId,
            });
            const orderInstances = json.orders.map(
                (order) => new OrderHistoryOrder(order)
            );
            cursor
                ? setOrders((prev) => [...prev, ...orderInstances])
                : setOrders(orderInstances);
            setCursor(json.cursor);
            setIsLoading(false);
        } catch (error) {
            if (!orders?.length) {
                setOrders([]);
            }
            setIsLoading(false);
        }
    }

    return { cursor, fetchOrderHistory, isLoading, orders };
};
