import React from "react";

// Components
import { FeatureFlagBoundary } from "components/Boundaries/FeatureFlagBoundary";
import { Panel } from "components/Panel/Panel";
import { StorylyGroup } from "components/StorylyGroup/StorylyGroup";

// Enums
import { StorylyCategory } from "enums/StorylyCategory";

// Globals
import { featureFlags } from "globals/featureFlags";

// Localization
import { useTranslation } from "localization/localization";

export const DashboardStoryly = () => {
    /**
     * Custom Hooks
     */

    const { t } = useTranslation();

    /**
     * End Hooks
     */

    return (
        <FeatureFlagBoundary
            featureFlags={[featureFlags.global.isStorylyContentEnabled]}
        >
            <Panel>
                <div className="panel__header">
                    <span className="panel__header__text">
                        {t("learn", { ns: "common" }).toUpperCase()}
                    </span>
                </div>
                <StorylyGroup
                    classNames={{
                        container: "",
                        storylyContainer: "dashboard",
                    }}
                    category={StorylyCategory.DASHBOARD}
                />
            </Panel>
        </FeatureFlagBoundary>
    );
};
