import React from "react";
import cx from "classnames";
import PropTypes from "prop-types";

// Components
import { CarouselItem } from "./components/CarouselItem/CarouselItem";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

// CSS
import styles from "./carousel.module.scss";

// Hooks
import { useCarousel } from "./hooks/useCarousel";

// Utils
import { handleKeyDown } from "utils/eventUtils";

export const Carousel = (props) => {
    const { children, classNames, config, headerText } = props;

    /**
     * Custom Hooks
     **/

    const {
        canGoLeft,
        canGoRight,
        handleShiftCarousel,
        hasMultipleItems,
        position,
    } = useCarousel(props);

    /**
     * End Hooks
     **/

    function renderHeader() {
        return (
            config.shouldShowHeader && (
                <div className="panel__header panel__header--space-between">
                    <span className="panel__header__text">{headerText}</span>
                    <div className={styles.arrows}>
                        <span
                            className={cx(styles.arrow, "material-icons", {
                                [styles["arrow--disabled"]]: !canGoLeft,
                            })}
                            onClick={() => handleShiftCarousel("left")}
                        >
                            arrow_back
                        </span>
                        <span
                            className={cx(styles.arrow, "material-icons", {
                                [styles["arrow--disabled"]]: !canGoRight,
                            })}
                            onClick={() => handleShiftCarousel("right")}
                        >
                            arrow_forward
                        </span>
                    </div>
                </div>
            )
        );
    }

    function renderNavInternal() {
        return (
            config.shouldShowNavInternal &&
            hasMultipleItems && (
                <div className={styles["nav-internal"]}>
                    <div
                        className={cx(styles.arrow, {
                            [styles["arrow--disabled"]]: !canGoLeft,
                        })}
                        onClick={() => handleShiftCarousel("left")}
                        onKeyDown={(e) =>
                            handleKeyDown(e, () => handleShiftCarousel("left"))
                        }
                        tabIndex={canGoLeft ? "0" : null}
                    >
                        <ChevronLeftIcon
                            height="28px"
                            width="28px"
                            color="#ffffff"
                        />
                    </div>
                    <div
                        className={cx(styles.arrow, {
                            [styles["arrow--disabled"]]: !canGoRight,
                        })}
                        onClick={() => handleShiftCarousel("right")}
                        onKeyDown={(e) =>
                            handleKeyDown(e, () => handleShiftCarousel("right"))
                        }
                        tabIndex={canGoRight ? "0" : null}
                    >
                        <ChevronRightIcon
                            height="28px"
                            width="28px"
                            color="#ffffff"
                        />
                    </div>
                </div>
            )
        );
    }

    function renderItems() {
        return (
            <div className={cx(styles.wrapper, classNames.wrapper)}>
                {children.map((child, i) => {
                    return (
                        <CarouselItem
                            {...props}
                            index={i}
                            key={i}
                            position={position}
                        >
                            {child}
                        </CarouselItem>
                    );
                })}
            </div>
        );
    }

    return (
        <div className={cx(styles.carousel, classNames.container)}>
            {renderHeader()}
            {renderNavInternal()}
            {renderItems()}
        </div>
    );
};

Carousel.defaultProps = {
    classNames: { container: "", wrapper: "" },
    config: {},
    headerText: "",
    itemsInViewCount: 2,
};

Carousel.propTypes = {
    classNames: PropTypes.object,
    children: PropTypes.arrayOf(PropTypes.node),
    config: PropTypes.object,
    headerText: PropTypes.string,
    items: PropTypes.array,
    itemsInViewCount: PropTypes.number,
};
