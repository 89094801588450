import React, { useEffect } from "react";
import cx from "classnames";

// Components
import { ButtonPhotoUpload } from "components/Buttons/ButtonPhotoUpload/ButtonPhotoUpload";
import { ButtonPrimary } from "components/Buttons/Buttons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { LettersPhotoDeleteModal } from "scenes/Letters/components/LettersPhotoManageModal/components/LettersPhotoDeleteModal/LettersPhotoDeleteModal";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";

// CSS
import styles from "css/letters/components/letters-photo-manage-modal.module.scss";

// Loggers
import { AnalyticsLogger } from "loggers/AnalyticsLogger";

export const LettersPhotoManageModal = (props) => {
    const {
        fetchMultiPhotoStatus,
        handleImageDelete,
        handleImageFileSelect,
        hasMultiPhoto,
        message,
        previewImage,
        setPreviewImage,
        setOnCompletePurchaseFlow,
        setShouldShowDeleteModal,
        setShouldShowPhotoManageModal,
        showBundleHighlightModal,
        shouldShowDeleteModal,
        shouldShowPhotoManageModal,
    } = props;

    /**
     * useEffect
     */

    useEffect(() => {
        fetchMultiPhotoStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldShowPhotoManageModal]);

    /**
     * End Hooks and Constants
     */

    function handleCloseModal() {
        setShouldShowPhotoManageModal(false);
    }

    function handleShowDeleteModal() {
        setShouldShowPhotoManageModal(false);
        setShouldShowDeleteModal(true);
    }

    function handleSelectImage(url) {
        url && setPreviewImage(url);
    }

    function handleShowBundleHighlightModal() {
        AnalyticsLogger.logUnlockMultiplePhotosButtonClicked();
        showBundleHighlightModal();
        setOnCompletePurchaseFlow(
            () => () => setShouldShowPhotoManageModal(true)
        );
        handleCloseModal();
    }

    function renderUploadButtons() {
        return (
            <div className={styles.buttons}>
                <ButtonPhotoUpload
                    cypressTestId="letters-photo-manage-modal-button-photo-upload-0"
                    handleImageFileSelect={handleImageFileSelect}
                    index={0}
                    isLocked={false}
                    isSquare={true}
                    onClick={{
                        unlocked: () =>
                            handleSelectImage(
                                message.customPhotoArray[0]?.binary
                            ),
                    }}
                    photo={{ url: message.customPhotoArray[0]?.binary }}
                    text={{
                        locked: "Unlock Photos",
                        unlocked: hasMultiPhoto ? "Upload Photo" : "Free Photo",
                    }}
                />
                <ButtonPhotoUpload
                    cypressTestId="letters-photo-manage-modal-button-photo-upload-1"
                    handleImageFileSelect={handleImageFileSelect}
                    index={1}
                    isLocked={!hasMultiPhoto}
                    isSquare={true}
                    onClick={{
                        locked: handleShowBundleHighlightModal,
                        unlocked: () =>
                            handleSelectImage(
                                message.customPhotoArray[1]?.binary
                            ),
                    }}
                    photo={{ url: message.customPhotoArray[1]?.binary }}
                    text={{
                        locked: "Unlock Photos",
                        unlocked: "Upload Photo",
                    }}
                />
                <ButtonPhotoUpload
                    cypressTestId="letters-photo-manage-modal-button-photo-upload-2"
                    handleImageFileSelect={handleImageFileSelect}
                    index={2}
                    isSquare={true}
                    isLocked={!hasMultiPhoto}
                    onClick={{
                        locked: handleShowBundleHighlightModal,
                        unlocked: () =>
                            handleSelectImage(
                                message.customPhotoArray[2]?.binary
                            ),
                    }}
                    photo={{ url: message.customPhotoArray[2]?.binary }}
                    text={{
                        locked: "Unlock Photos",
                        unlocked: "Upload Photo",
                    }}
                />
                <ButtonPhotoUpload
                    cypressTestId="letters-photo-manage-modal-button-photo-upload-3"
                    handleImageFileSelect={handleImageFileSelect}
                    index={3}
                    isSquare={true}
                    isLocked={!hasMultiPhoto}
                    onClick={{
                        locked: handleShowBundleHighlightModal,
                        unlocked: () =>
                            handleSelectImage(
                                message.customPhotoArray[3]?.binary
                            ),
                    }}
                    photo={{ url: message.customPhotoArray[3]?.binary }}
                    text={{
                        locked: "Unlock Photos",
                        unlocked: "Upload Photo",
                    }}
                />
            </div>
        );
    }

    function renderDeleteModal() {
        return (
            <LettersPhotoDeleteModal
                handleImageDelete={handleImageDelete}
                message={message}
                previewImage={previewImage}
                shouldShow={shouldShowDeleteModal}
                setShouldShowDeleteModal={setShouldShowDeleteModal}
                setShouldShowPhotoManageModal={setShouldShowPhotoManageModal}
            />
        );
    }

    function renderLettersPhotoManageModal() {
        return (
            <Modal
                className={styles["letters-photo-manage-modal"]}
                cypressTestId="letters-photo-manage-modal"
                cypressCloseButtonTestId="letters-photo-manage-modal-close"
                headerText="Add Photos"
                headerSubText="Edit and upload your photos"
                onClose={handleCloseModal}
                onHide={handleCloseModal}
                size="small"
                show={shouldShowPhotoManageModal}
            >
                <div className={styles.content}>
                    <div
                        className={cx(styles["preview-image"], {
                            [styles["preview-image--empty"]]: !previewImage,
                        })}
                        style={{
                            backgroundImage: previewImage
                                ? `url(${previewImage})`
                                : "",
                        }}
                    >
                        {previewImage ? (
                            <div
                                className={styles.deletePhoto}
                                onClick={() =>
                                    handleShowDeleteModal(previewImage)
                                }
                            >
                                <DeleteOutlineIcon className={styles.icon} />
                            </div>
                        ) : (
                            <div className={styles.empty}>
                                <Text
                                    classes={styles.headline}
                                    isBold={true}
                                    type="h4"
                                >
                                    Photo Preview
                                </Text>
                                <Text
                                    classes={styles.description}
                                    type="caption"
                                >
                                    You can preview your photos once they are
                                    added
                                </Text>
                            </div>
                        )}
                    </div>
                    {renderUploadButtons()}
                    <Text classes={styles.text} type="small">
                        In case of a billing issue, your primary photo will
                        always be included in your letter. Your additional three
                        photos will be removed.
                    </Text>
                    {!hasMultiPhoto && (
                        <ButtonPrimary
                            classes={styles.submitButton}
                            onClick={() => {
                                handleCloseModal();
                            }}
                            text="Add Photos"
                        />
                    )}
                </div>
            </Modal>
        );
    }

    return (
        <>
            {renderLettersPhotoManageModal()}
            {renderDeleteModal()}
        </>
    );
};
