import i18n from "i18next";
import { useTranslation as useI18nTranslation } from "react-i18next";

const getLanguage = () => {
    return i18n.language;
};

const setLanguage = (lang) => {
    i18n.changeLanguage(lang);
};

export const t = (key, options) => {
    return i18n.t(key, options);
};

export const useTranslation = () => {
    const { t } = useI18nTranslation();
    return {
        t,
        getLanguage,
        setLanguage,
    };
};
