import React from "react";

// Components
import { ButtonSecondary, ButtonInvisible } from "components/Buttons/Buttons";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Modal } from "components/Modals/Modal";
import { Text } from "components/Text/Text";

// CSS
import styles from "css/letters/components/letters-photo-delete-modal.module.scss";

export const LettersPhotoDeleteModal = (props) => {
    const {
        handleImageDelete,
        message,
        previewImage,
        setShouldShowDeleteModal,
        setShouldShowPhotoManageModal,
        shouldShow,
    } = props;

    function handleCloseModal() {
        setShouldShowDeleteModal(false);
        setShouldShowPhotoManageModal(true);
    }

    function handleDeletePhoto() {
        handleImageDelete(
            message.customPhotoArray
                .map((image) => image.binary)
                .indexOf(previewImage)
        );
        handleCloseModal();
    }

    return (
        <Modal
            className={styles["letters-photo-delete-modal"]}
            cypressTestId="letters-photo-delete-modal"
            dialogClassName={styles.dialog}
            onClose={handleCloseModal}
            onHide={handleCloseModal}
            size="small"
            show={shouldShow}
            shouldHideHeader={true}
        >
            <div className={styles.content}>
                <DeleteOutlineIcon className={styles.icon} />
                <Text classes={styles.header} isBold={true} type="h4">
                    Are you sure?
                </Text>
                <Text classes={styles["text"]} type="p">
                    Are you sure you want to delete this image from your letter?
                </Text>
                <div className={styles.buttons}>
                    <ButtonSecondary
                        classes={styles["button-secondary"]}
                        onClick={handleDeletePhoto}
                        text="Delete Photo"
                    />
                    <ButtonInvisible onClick={handleCloseModal} text="Cancel" />
                </div>
            </div>
        </Modal>
    );
};
