import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// Formatters
import { formatters } from "./formatters";

// Resources
import commonEN from "./locales/en/common.json";
import dashboardEN from "./locales/en/dashboard.json";
import notificationsEN from "./locales/en/notifications.json";
import timeEN from "./locales/en/time.json";
import commonES from "./locales/es/common.json";
import dashboardES from "./locales/es/dashboard.json";
import notificationsES from "./locales/es/notifications.json";
import timeES from "./locales/es/time.json";

// Constants
const debug = false;
const fallbackLng = "en";
const lng = "en";

i18n.use(initReactI18next).init({
    debug,
    fallbackLng,
    interpolation: {
        escapeValue: false,
        format: (value, format, lng, options) => {
            if (formatters[format]) {
                return formatters[format](value, format, lng, options);
            }
            return value;
        },
    },
    lng,
    ns: ["common", "dashboard", "notifications", "time"],
    resources: {
        en: {
            common: commonEN,
            dashboard: dashboardEN,
            notifications: notificationsEN,
            time: timeEN,
        },
        es: {
            common: commonES,
            dashboard: dashboardES,
            notifications: notificationsES,
            time: timeES,
        },
    },
});
